@keyframes caret-blink {
    0%,
    70%,
    100% {
        opacity: 1;
    }

    20%,
    50% {
        opacity: 0;
    }
}

.caret {
    height: 1rem;
    width: 1px;
    animation: caret-blink 1.2s ease-out infinite;
    background-color: var(--ak-color-typography-text);
}

.slot {
    color: var(--ak-color-typography-text);
}

.separator {
    color: var(--ak-color-typography-text);
    font-size: 2.25rem;
    line-height: 2.5rem;
}