.root {
    font-size: 16px;
    font-family: var(--ak-theme-text-font-family, 'sans-serif');
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--ak-color-typography-text, #000000);
}

.root pre {
    font-family: var(--ak-text-code-font-family, 'monospace');
}

.root hr {
    border-color: var(--ak-theme-color-hairline);
}

.root ul,
.root ol {
    font-size: 1rem;
    line-height: 1.5;
    margin-left: 0;
}

.root small {
    font-size: 0.875rem;
}