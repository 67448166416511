.accordionContent {
    overflow: hidden;
    transition: height 0.3s ease-out;
}

[data-state="open"] .accordionContent {
    animation: accordion-down 0.2s ease-out;
}

[data-state="closed"] .accordionContent {
    animation: accordion-up 0.2s ease-out;
}

@keyframes accordion-down {
    from {
        height: 0;
    }

    to {
        height: var(--radix-accordion-content-height);
    }
}

@keyframes accordion-up {
    from {
        height: var(--radix-accordion-content-height);
    }

    to {
        height: 0;
    }
}