:root {
    /* 
        AidKit Colors
    */
    --ak-color-aidkit-red: #dd3f45;
    --ak-color-aidkit-blue: #2B2C60;

    --ak-color-brand-blue-50: #f2f4fc;
    --ak-color-brand-blue-100: #e2e6f7;
    --ak-color-brand-blue-200: #ccd3f1;
    --ak-color-brand-blue-300: #a9b7e7;
    --ak-color-brand-blue-400: #7f92db;
    --ak-color-brand-blue-500: #6170d0;
    --ak-color-brand-blue-600: #4d56c3;
    --ak-color-brand-blue-700: #4345b2;
    --ak-color-brand-blue-800: #3b3b92;
    --ak-color-brand-blue-900: #2b2c60;
    --ak-color-brand-blue-950: #232348;

    --ak-color-brand-red-50: #fdf3f3;
    --ak-color-brand-red-100: #fce4e5;
    --ak-color-brand-red-200: #faced0;
    --ak-color-brand-red-300: #f5acaf;
    --ak-color-brand-red-400: #ed7c80;
    --ak-color-brand-red-500: #dd3f45;
    --ak-color-brand-red-600: #cd353b;
    --ak-color-brand-red-700: #ac292e;
    --ak-color-brand-red-800: #8e262a;
    --ak-color-brand-red-900: #772528;
    --ak-color-brand-red-950: #400f11;

    /* 
        Themeable Values

        These are the default values, but can be overridden by a custom theme config
        with a <ThemeProvider />
    */
    --ak-theme-color-button-primary-brand-background: #2B2C60;
    --ak-theme-color-button-primary-brand-background-hover: #3b3b92;
    --ak-theme-color-button-primary-brand-text: #FFFFFF;
    --ak-theme-color-button-primary-brand-icon: #FFFFFF;
    --ak-theme-color-hairline: #e5e7eb;
    --ak-theme-text-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    /*
        AidKit default component styling
    */

    --ak-text-code-font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, "Courier New", Courier, Roboto Mono, monaco, monospace;
    --ak-text-measure-normal: 30em;
    --ak-text-measure-wide: 34em;
    --ak-text-measure-narrow: 20em;

    --ak-color-button-primary-brand-text-active: #3b3b92;
    --ak-color-button-primary-background: #2B2C60;
    --ak-color-button-primary-background-hover: #3b3b92;
    --ak-color-button-primary-icon: #FFFFFF;
    --ak-color-button-primary-text: #FFFFFF;
    --ak-color-button-primary-text-active: #3b3b92;
    --ak-color-button-secondary-border: 1px solid #dee2e6;
    --ak-color-button-secondary-border-radius: 6px;
    --ak-color-button-secondary-background: #ffffff;
    --ak-color-button-secondary-background-hover: #e8e8e8;
    --ak-color-button-secondary-icon: #FFFFFF;
    --ak-color-button-secondary-text: #000000;
    --ak-color-button-secondary-text-active: #3b3b92;
    --ak-color-button-error-background: #2B2C60;
    --ak-color-button-error-background-hover: #3b3b92;
    --ak-color-button-error-icon: #FFFFFF;
    --ak-color-button-error-text: #FFFFFF;
    --ak-color-button-error-text-active: #3333FF;
    --ak-color-button-disabled-background: #e0e0e0;
    --ak-color-button-disabled-background-hover: #3b3b92;
    --ak-color-button-disabled-icon: #FFFFFF;
    --ak-color-button-disabled-text: #FFFFFF;
    --ak-color-button-disabled-text-active: #3b3b92;

    --ak-color-typography-text: #171717;
    --ak-color-typography-text-highlight-background: #fcf8e3;
    --ak-color-typography-text-highlight-text: #000000;

    --ak-color-typography-link: #3b3b92;
    --ak-color-typography-link-hover: #3366CC;
}