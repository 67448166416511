.button:focus-visible {
    outline-style: solid;
    outline-width: 2px;
    outline-offset: 2px;
    outline-color: #4f46e5;
}.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    font-weight: 700;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
}

.button--iconPlacement-left svg {
    margin-right: 5px;
}

.button--iconPlacement-right svg {
    margin-left: 5px;
}

.button--iconPlacement-none svg {
    margin: 0;
}

.button--sm svg {
    height: 1.25rem;
    width: 1.25rem;
}

.button--md svg {
    height: 1.5rem;
    width: 1.5rem;
}

.button--lg svg {
    height: 2rem;
    width: 2rem;
}

.button--rectangle {
    border-radius: 0.25rem;
}

.button--circle {
    border-radius: 9999px;
    height: 4rem;
    width: 4rem;
}

.button--rectangle.button--sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
}

.button--rectangle.button--md {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.button--rectangle.button--lg {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.button--rectangle.button--link {
    padding: 0px;
}

.button--circle.button--sm {
    padding: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    height: 2rem;
    width: 2rem;
}

.button--circle.button--md {
    padding: 0.375rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: 3rem;
    width: 3rem;
}

.button--circle.button--lg {
    padding: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    height: 4rem;
    width: 4rem;
}

.button--link {
    text-decoration: underline;
    color: var(--ak-color-typography-link);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
}

.button--link:hover,
.button--link:active {
    color: var(--ak-color-typography-link-hover);
}

.button--unstyled {}

.button--primary {
    background-color: var(--ak-theme-color-button-primary-brand-background);
    color: var(--ak-theme-color-button-primary-brand-text);
}

.button--secondary {
    background-color: var(--ak-color-button-secondary-background);
    border: var(--ak-color-button-secondary-border);
    color: var(--ak-color-button-secondary-text);
}

.button--primary:hover,
.button--primary:active {
    background-color: var(--ak-theme-color-button-primary-brand-background-hover);
}

.button--primary-loading {
    --tw-border-opacity: 1;
    border-color: rgb(191 219 254 / var(--tw-border-opacity, 1));
    border-top-color: transparent;
}

.button--secondary:hover, .button--secondary:active {
    background-color: var(--ak-color-button-secondary-background-hover);
}

.button--secondary-loading {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
    border-top-color: transparent;
}

.button--disabled {
    background-color: var(--ak-color-button-disabled-background);
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.button--disabled:hover {
    background-color: var(--ak-color-button-disabled-background);
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.button--danger {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.button--danger-loading {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity, 1));
    border-top-color: transparent;
}

.button--danger:hover, .button--danger:active {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.loadingIndicator {
    animation-duration: 800ms;
}

.loadingIndicator--sm {
    margin: 0.25rem;
    height: 0.5rem;
    width: 0.5rem;
    border-width: 1px;
}

.loadingIndicator--md {
    margin: 0.125rem;
    height: 1rem;
    width: 1rem;
}

.loadingIndicator--lg {
    margin: 0.125rem;
    height: 1.5rem;
    width: 1.5rem;
}