@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.markdown {
  ul,
  ol {
    list-style: revert;
    margin-left: 20px;
  }
}

/* This helps prevent the labels on pie charts from gettting cut off */
.recharts-wrapper svg {
  overflow: inherit !important;
}

.grow-wrap {
  display: grid;
}

.grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.grow-wrap>textarea {
  resize: none;
  overflow: hidden;
}

.grow-wrap>textarea,
.grow-wrap::after {
  border: 1px solid #ccc;
  padding: 0.5rem;
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
}

@keyframes blink {
  from, to { opacity: 1 }
  50% { opacity: 0 }
}

.blink {
  animation: blink 1.75s ease-in infinite;
}
