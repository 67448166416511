.measure--normal {
    max-width: 30em;
}

.measure--wide {
    max-width: 34em;
}

.measure--narrow {
    max-width: 20em;
}

.measure--none {
    max-width: none;
}

/*  To ensure consistent vertical rhythm, we will use a relative units based on a base 16px font-size and 24px line-height.
    We will set a margin on the bottom of headings relative to the desired 24px line-height.

    Each heading will have a line-height and margin that is (generally) a multiple of the line-height (24px). 
    This is calculated as a relative em unit of the heading's specified font-size.

    For example, an h3 heading with font-size 3rem (assuming the root font-size is set to 16px) results in:
    3rem * 16px = 48px font size
    24px (line-height) / 48px = 0.5em margin (in other words, 0.5em of 48px is 24px)

    Using em for our margins will ensure the type scale remains in tact when the browser's font-size changes.

    Line-height must remain unitless:
    If a line-height were to be set using relative units like % or em, there is a risk that 
    the line-height calculation inherits from a parent element. This will cause unintended side effects.
*/

.h1 {
    font-size: 3rem;            /* 48px */
    line-height: 1.1;           /* 53px - adding a bit extra breathing space for very large titles */
    font-weight: 700;
    margin: 0 0 0.5em 0;        /* 24px */
    letter-spacing: -0.015em;
}

.h2 {
    font-size: 2.25rem;         /* 36px */
    line-height: 1.333;         /* ~48px */
    font-weight: 700;
    margin: 0 0 0.667em 0;      /* ~24px */
    letter-spacing: -0.01em;
}

.h3 {
    font-size: 1.5rem;    /* 24px */
    line-height: 1;       /* 24px */
    font-weight: 700;
    margin: 0 0 1em 0;    /* 24px */
}

.h4 {
    font-size: 1.25rem;    /* 20px */
    line-height: 1.2;      /* 24px */
    font-weight: 600;
    margin: 0 0 1.2em 0;   /* 24px */
}

.h5 {
    font-size: 1rem;      /* 16px */
    line-height: 1.5;     /* 24px */
    font-weight: 600;
    margin: 0 0 1.5em 0;  /* 24px */
}

.h6 {
    font-size: 0.875rem;    /* 14px */
    line-height: 1.7143;    /* ~24px */
    font-weight: 600;
    margin: 0 0 1.7143em 0; /* ~24px */
}

/* Mobile adjustments */
@media (max-width: 640px) {
    .h1 {
        font-size: 1.875rem;    /* 30px */
        line-height: 1;         /* 30px */
        margin: 0 0 0.8em 0;    /* 24px */
    }

    .h2 {
        font-size: 1.5rem;     /* 24px */
        line-height: 1;        /* 24px */
        margin: 0 0 1em 0;     /* 24px */
    }

    .h3 {
        font-size: 1.25rem;    /* 20px */
        line-height: 1.2;      /* 24px */
        margin: 0 0 1.2em 0;   /* 24px */
    }

    .h4 {
        font-size: 1.125rem;    /* 18px */
        line-height: 1.333;     /* 24px */
        margin: 0 0 1.333em 0;  /* 24px */
    }

    .h5 {
        font-size: 0.875rem;    /* 14px */
        line-height: 1.7143;    /* 24px */
        margin: 0 0 1.7143em 0; /* 24px */
    }

    .h6 {
        font-size: 0.75rem;     /* 12px */
        line-height: 1.667;     /* 20px */
        margin: 0 0 1em 0;      /* 24px */
    }
}