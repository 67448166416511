/* We are using px for spacing in this component to ensure exact cross-browser support.
   We need the <textarea> and <pre> to overlap exactly and any deviation will cause UX issues
   because the caret cursor will not line up.
*/
.codeEditor {
    margin: 0;
    padding: 8px;
    width: 100%;
    min-height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    -moz-tab-size: 2;
      -o-tab-size: 2;
         tab-size: 2;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: keep-all;
    -webkit-hyphens: none;
    hyphens: none;
    overflow: hidden;
    box-sizing: border-box;
    /* We apply a transparent border on both overlapping elements to ensure 
       consistent box sizing.
    */
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
}

.codeTypography {
    font-family: var(--ak-text-code-font-family, 'monospace');
    font-size: 14px;
    line-height: 21px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* The wrapper container is set to position: relative 
   and the children (<textarea> and <pre>) are set to absolute
   so that we can line them up to overlap exactly over each other.
*/
.container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 80px;
}

/* The actual textarea component the user types in.
   We make it transparent so that we can surface the 
   syntax-highlighted version from below the textarea.
*/
.textArea {
    z-index: 1;
    color: transparent;
    background: transparent;
    caret-color: #000000;
    resize: none;
    border-color: transparent;
}

/* Syntax highlighted code that is viewable below the textarea */
.highlighter {
    z-index: 0;
    background: #FFFFFF;
    color: #000000;
    pointer-events: none;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
    overflow-x: hidden;
    border-color: #DEE2E6;
}

.code {
    box-sizing: border-box;
}

/*
*  PrismJS Theme for tokenized code content
*/

/* Comments */
.code :global(.token.comment),
.code :global(.token.prolog),
.code :global(.token.doctype),
.code :global(.token.cdata) {
    color: #5B6A88;
    font-style: italic;
}

/* Namespace */
.code :global(.token.namespace) {
    opacity: 0.7;
}

/* Strings */
.code :global(.token.string),
.code :global(.token.attr-value) {
    color: #0A5E47;
}

/* Punctuation and basic operators */
.code :global(.token.punctuation),
.code :global(.token.operator) {
    color: #4A5568;
}

/* Keywords and operators */
.code :global(.token.keyword),
.code :global(.token.operator) {
    color: #be185d;
}

/* Variables, constants, etc. */
.code :global(.token.entity),
.code :global(.token.url),
.code :global(.token.symbol),
.code :global(.token.number),
.code :global(.token.boolean),
.code :global(.token.variable),
.code :global(.token.constant),
.code :global(.token.property),
.code :global(.token.regex),
.code :global(.token.inserted) {
    color: #0040C1;
}

/* Rules and selectors */
.code :global(.token.atrule),
.code :global(.token.attr-name),
.code :global(.token.selector) {
    color: #0e7490;
}

/* Functions and tags */
.code :global(.token.function),
.code :global(.token.deleted),
.code :global(.token.tag) {
    color: #B91C1C;
}

/* Function variables */
.code :global(.token.function-variable) {
    color: #7e22ce;
}

/* Tags and keywords */
.code :global(.token.tag),
.code :global(.token.selector),
.code :global(.token.keyword) {
    color: #1A56DB;
}

/* Parameters and types */
.code :global(.token.parameter),
.code :global(.token.typing),
.code :global(.token.type-declaration),
.code :global(.token.type-annotation) {
    color: #4B5563;
}

/* Class names */
.code :global(.token.class-name),
.code :global(.token.maybe-class-name),
.code :global(.token.interface),
.code :global(.token.enum) {
    color: #0369A1;
}

/* Built-ins and modules */
.code :global(.token.builtin),
.code :global(.token.char),
.code :global(.token.changed),
.code :global(.token.exports),
.code :global(.token.module) {
    color: #c2410c;
}

/* Titles and sections */
.code :global(.token.title),
.code :global(.token.section) {
    color: #1E40AF;
    font-weight: bold;
}

/* Style modifiers */
.code :global(.token.italic) {
    font-style: italic;
}

.code :global(.token.bold) {
    font-weight: bold;
}

/* Lists */
.code :global(.token.list),
.code :global(.token.list-item) {
    color: #374151;
}

/* Code blocks */
.code :global(.token.code),
.code :global(.token.code-line) {
    color: #B91C1C;
    background-color: #F3F4F6;
}

/* URLs */
.code :global(.token.url) {
    color: #2563EB;
}

/* Strike-through */
.code :global(.token.strike) {
    color: #6B7280;
}

/* Tables */
.code :global(.token.table) {
    color: #374151;
}

/* Important elements */
.code :global(.token.important) {
    font-weight: bold;
    font-style: italic;
}