
body, html {
    background-color: white;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.info::after {
    content: ' \24D8';
}

.markdown hr {
    display: none;
}
.markdown hr ~ * {
    display: none;
}
.markdown hr + p {
    display: block;
    color: #333;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
}

.markdown hr + p::after {
    content: '>';
    color: #7000ea;
    float: right;
    margin-right: 10px;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.active-section-link {
    font-weight: bold;
}

.section-link {
    margin-right: 20px;
}

#root {
    width: 100%;
    height: 100%;
}

#applicants-table tr td:first-child {
    display: flex;
    justify-content: space-between;
    /* display flex makes borders weird */
    border-left: none;
    border-right: none;
    border-bottom: none;
}

#applicants-table tr:first-child td:first-child {
    border-top: none;
}

.survey-question,
.chunk {
    padding: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.survey-question:not(.highlight) {
    border-left: 4px solid #fff;
}

.survey-question.highlight {
    border-left: 4px solid #E33D44;
}

.valid-input {
    border: 2px solid green !important;
}

.invalid-input {
    border: 2px solid #E33D44 !important;
}

.spinner {
    margin: 5px auto;
    margin-left: 20px;
    width: 50px;
    height: 20px;
    text-align: center;
    font-size: 10px;
    display: inline-block;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

.spinner.red>div {
    background-color: #a80c0c;
}

.spinner>div {
    background-color: #bbb;
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
    }
}

@keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
    }
}

.old-history {
    color: #999;
}

tr.color-hidden td {
    background-color: #eee;
}

.cursor-pointer {
    cursor: pointer;
}

.sent-text {
    float: right;
    max-width: 85%;
    /* background-color: yellowgreen; */
}

.received-text {
    float: left;
    max-width: 85%;
}

.sent-text small {
    text-align: right;
    float: right;
}

.text-message-container {
    max-height: 500px;
    overflow-y: scroll;
}

.alternate-color:nth-child(even) {
    background-color: #fff!important;
}

.alternate-color:nth-child(odd) {
    background-color: #eee!important;
}

.red-placeholder::placeholder {
    color: #E33D44 !important;
}

.blue-placeholder::placeholder {
    color: blue !important;
}

.bg-aidkit-blue {
    background-color: #2B2C60;
    color: white;
}

.aidkit-blue {
    color: #2B2C60;
}

.explanation ul {
    list-style: disc;
    margin-left: 20px;
}

.explanation ol {
    list-style: auto;
    margin-left: 20px;
}

.applicant-led .applicant-led-hidden {
    display: none !important;
}

.explanation > div > *:first-child {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
}

.collapsible > div > *:first-child {
    user-select: all!important;
}

.explanation img {
    max-width: 100%;
}

.collapsible > div > *:first-child:after {
    content: '';
}

.collapsed > div > *:first-child:after {
    content: var(--click-to-expand)!important;
    background-color: rgb(248, 249, 251);
    border-bottom-color: rgb(221, 228, 235);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(222, 226, 230);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(222, 226, 230);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(222, 226, 230);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top-style: solid;
    border-top-width: 1px;
    box-sizing: border-box;
    color: rgb(90, 90, 90);
    cursor: pointer;
    direction: ltr;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-variant-caps: normal;
    font-weight: 500;
    height: 38px;
    width: fit-content;
    letter-spacing: normal;
    line-height: 20px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 8px;
    overflow-x: visible;
    overflow-y: visible;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    text-align: center;
    text-indent: 0px;
    text-shadow: none;
    text-transform: none;
    word-spacing: 0px;
}

.collapsed *:not(:first-child) {
    display: none;
}

.name-and-status {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #ccc;
    background-color: white;
}

.center-hack {
    text-align: center;
}

@media (min-width: 768px) { 
    .center-hack-left {
        text-align: left;
    }
}

legend {
    font-size: 1rem;
}

legend ul {
    list-style: disc;
    padding-left: 10px;
}

.guard img {
    max-width: 100%;
}

/** Search Icon in Config Page */
.svg-icon.search-icon {
    display: inline-block;
    width: 16px; height: 16px;
    /* On hover: blue strokes */
    /* On click: thicker black strokes  */
}
.svg-icon.search-icon:focus .search-path, .svg-icon.search-icon:hover .search-path {
    stroke: #299ecc;
}
.svg-icon.search-icon:active .search-path {
    stroke: #111516;
    stroke-width: 2px;
}
/* Hide increment and decrement spinners for <input type="number" /> */
.no-num-spinners::-webkit-inner-spin-button,
.no-num-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-num-spinners {
  -moz-appearance: textfield;
}