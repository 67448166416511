.toast {
    width: 100%;
}@media (min-width: 640px) {.toast {
        max-width: -moz-fit-content;
        max-width: fit-content;
    }
}.toast {
    pointer-events: auto;
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
}.toast > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}.toast {
    overflow: hidden;
    border-radius: 0.375rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 3rem;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    cursor: grab;
}

.toastIcon {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    padding-left: 0.125rem;
    padding-right: 0.125rem;
}

.toast:active {
    cursor: grabbing;
}

/* Targets any children of the text container and ensures that long strings 
   are broken up and made scrollable.

   Manually defining here because overflow-wrap is not yet implemented in Tailwind
*/
.toastTextContainer * {
    overflow-wrap: anywhere;
}

.toastText {
    cursor: text;
}

.toastAction {
    padding-top: 1.25rem;
    cursor: default;
}

.toast p {
    margin: 0;
}

.toast h5 {
    margin: 0;
}

.toast--default {
    color: var(--ak-color-typography-text, black);
}

.toast--default .toastIcon > svg {
    color: var(--ak-color-typography-text, black);
}

.toast--success {
    --tw-border-opacity: 1;
    border-color: rgb(22 101 52 / var(--tw-border-opacity, 1));
}

.toast--success .toastIcon > svg {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}

.toast--error {
    --tw-border-opacity: 1;
    border-color: rgb(153 27 27 / var(--tw-border-opacity, 1));
}

.toast--error .toastIcon > svg {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.toastClose {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border-radius: 0.375rem;
    padding: 0.25rem;
}

.toastClose:hover::before {
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.toastClose::before {
    position: absolute;
    inset: 0px;
    border-radius: 0.375rem;
    border-width: 1px;
    content: var(--tw-content);
    border-color: transparent;
}

/* Mobile adjustments */
@media (max-width: 640px) {
    .toast > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem * var(--tw-space-x-reverse));
        margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .toast {
        padding: 0.25rem;
        padding-right: 2.75rem;
    }

    .toastClose {
        position: absolute;
        right: 0.25rem;
        top: 0.25rem;
        border-radius: 0.375rem;
        padding: 0.25rem;
    }
}