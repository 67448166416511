.tab-label--desktop {
    display: inline-flex;
    align-items: center;
    border-bottom-width: 2px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    cursor: pointer;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    border-color: transparent;
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}.tab-label--desktop:hover {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.tab-label--desktop-selected {
    border-color: var(--ak-color-brand-blue-800);
}

.tab-label--desktop-selected:hover {
    border-color: var(--ak-color-brand-blue-800);
}

.tab-label--desktop-selected {
    color: var(--ak-color-brand-blue-800);
}

.tab-label--desktop-selected:hover {
    color: var(--ak-color-brand-blue-800);
}

.tab-label--desktop svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

@media (min-width: 640px) {.tab-list--mobile {
        display: none;
    }
}

.tab-list--mobile {
    width: 100%;
    border-radius: 0.375rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 2.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
}

.tab-list--mobile:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tab-list--mobile {

    /* Temporarily override until we have a standard Select Dropdown component */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data: image/svg+xml, %3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' %20fill%3D'none' %20viewBox%3D'0%200%2020%2020' %3E%3Cpath%20stroke%3D'%236b7280' %20stroke-linecap%3D'round' %20stroke-linejoin%3D'round' %20stroke-width%3D'1.5' %20d%3D'M6%208l4%204%204-4' %2F%3E%3C%2Fsvg%3E");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
}

.tab-list--desktop {
    display: none;
    border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

@media (min-width: 640px) {.tab-list--desktop {
        display: block;
    }
}

.tab-list--desktop-tabs {
    margin-bottom: -1px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;
}

.tab-count {
    border-radius: 9999px;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
}

@media (min-width: 640px) {.tab-count {
        display: block;
    }
}

@media (min-width: 768px) {.tab-count {
        display: inline-block;
    }
}

.tab-count {
    margin-left: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.tab-count--info.tab-count--selected {
    background-color: var(--ak-color-brand-blue-100);
    color: var(--ak-color-brand-blue-700);
}

.tab-count--warning {
    --tw-bg-opacity: 1;
    background-color: rgb(254 243 199 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(146 64 14 / var(--tw-text-opacity, 1));
}

.tab-count--error {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity, 1));
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity, 1));
}